import React, { useState, useRef } from "react";
import axios from "axios";
import Editor from 'react-simple-wysiwyg';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from "react-router-dom";

export default function UpdateBlog(){
  const navigate = useHistory();

  const location = useLocation();

  let timerInterval;

  const [selectedFile, setSelectedFile] = useState(null);

	const [imageLink, setImageLink] = useState(location.state.state.image === "" ? null : location.state.state.image);

	const [validationError, setValidationError] = useState(null);

	const fileInputRef = useRef(null);

	
  const [html, setHtml] = React.useState(location.state.state.body);

  const [title, setTitle] = React.useState(location.state.state.title);

  const [body, setBody] = React.useState("");

  const [inputs, setInputs] = useState({});

  function onChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setHtml(event.target.value);
    setBody(value);
    setInputs(values => ({...values, [name]: value}));
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setTitle(value);
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) =>{
    event.preventDefault();

    let headers= { 
      'Accept':'*/*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    let image_link = "";
    if(imageLink){
      image_link = imageLink
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('image', image_link);
    
    console.log(formData);
    axios.put('https://paytack.com/paytackapi/blogs/', formData, {headers}).then(function(response){
            if(response.data['status'] == '1'){
              Swal.fire({
                icon:  "success",
                title: "Success!",
                html: "Blog created. redirecting to blogs.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  // timerInterval = setInterval(() => {
                  //   timer.textContent = `${Swal.getTimerLeft()}`;
                  // }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                  navigate.push('/admin/blogs/', {
                    // state: {
                    //   id: blog.id,
                    //   title: blog.title,
                    //   body: blog.body,
                    //   image: blog.image,
                    //   dateadded: blog.dateadded,
                    //   addedby: "John Doe",
                    //   status: 1
                    // }
                  })
                }
              });
            }
    });
  }

  const handleFileChange = (event) => {

    const file = event.target.files[0];
    if(file)
    {
        const allowedExtension = ['.jpg', '.png'];
        const selectedFileExtension = file.name.split('.').pop().toLowerCase();
        if(allowedExtension.includes('.' + selectedFileExtension))
        {
            setSelectedFile(file);
            handleUpload();
            setValidationError(null);
        }
        else
        {
            setSelectedFile(null);
            setValidationError('Invalid file extension. Please select a file with .jpg or .png extension.');
            fileInputRef.current.value = '';
        }
    }

};

const handleUpload = async() => {
    if(selectedFile)
    {
        let headers= { 
            'Accept':'*/*',
            // 'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Headers' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }
        const formData = new FormData();
        formData.append('file', selectedFile);
        // const response = await fetch('http://localhost:8080/paytack/uploads', {
        //     method : 'POST',
        //     body : formData
        // });

        console.log(formData);
        // const responseData = await response.json();
        axios.post('https://paytack.com/paytackapi/files/', formData, {headers}).then(function(response){
            console.log("++++++++++++++++++++++++++++++++");
            console.log(response.data["url"]);
            setImageLink(response.data["url"]);

            setInputs(values => ({...values, "image": response.data["url"]}));
        });
        // setImageLink(responseData.image_link);
        // fileInputRef.current.value = '';
    }
    else
    {
        setValidationError('Please select a file before uploading.');
    }
};
    return (
        <>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Edit Blog</h6>
                
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={handleSubmit}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Blog Details
                </h6>
                <div className="flex flex-wrap">
                <div className="w-full lg:w-9/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue=""
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-9/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Body/Text
                      </label>
                      <Editor name="body" value={html} onChange={onChange} tagName="p" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>
                  <div className="w-full lg:w-9/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Upload Feature Image
                      </label>
                      <br></br>
                      <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                      <br></br> <br></br>
                      <button onClick={handleUpload}
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        >
                        Upload
                        </button>
                      <br></br>
                        {validationError && (
                            <p className="text-danger">{validationError}</p>
                        )}

                        {imageLink && (
                            <div>
                                <p><b>Current Image : </b></p>
                                <img
                                alt="Current Image"
                                className="align-middle border-none h-auto rounded-lg"
                                style={{
                                  objectFit: "cover",
                                  width: "100px",
                                  height: "100px"
                                }}
                                // src={"https://picsum.photos/seed/picsum/200"}
                                src={imageLink}
                              />
                            </div>
                        )}
                    </div>
                  </div>
                </div>  
    
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <br></br> 
                <button onClick={handleSubmit}
                className="bgPrimaryColor text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                >
                Update Blog
                </button>

              </form>
            </div>
          </div>
        </>
      );
}