import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";

// components

export default function RecentListBlog({ color }) {
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      function getBlogs(){
        axios.get('https://paytack.com/paytackapi/blogs/').then(function(response){
          // console.log(response.data);
          setBlogs(response.data);
        });
      
      }
      getBlogs();
    }, []);
  
  

  function getUser(){
    let headers= { 
      'Accept':'*/*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    const formData = new FormData();
    formData.append('email', "krumsime@gmail.com");
    formData.append('password', "4e3w2q1");
    
    // console.log(formData);
    axios.post('https://paytack.com/paytackapi/auth/', formData, {headers}).then(function(response){
        console.log(response);
    });
  
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Recent Blogs</h6>
            <Link to="/admin/blogs">
            <button
              className="bgPrimaryColor text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              
                All Blogs
             
            </button>
            </Link>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Title
                </th>
                <th
                  style={{width: "230px"}}
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Body
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Author
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Date Posted
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                
              </tr>
            </thead>
            <tbody>
            {blogs.reverse().map((blog) =>
              <tr key={blog.id}>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left flex items-center">
                  
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    {blog.title}
                  </span>
                </td>
                <td className="border-t-0 px-6 max-w-200-px align-middle border-l-0 border-r-0 text-xs p-4">
                  <div className="line-clamp" dangerouslySetInnerHTML={{__html: blog.body}} />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="flex">
                    John Doe
                  </div>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <div className="flex">
                    {blog.dateadded}
                  </div>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {blog.status===0? <i className="fas fa-circle text-orange-500 mr-2">Pending</i>  : blog.status===1? <i className="fas fa-circle text-emerald-500 mr-2">&nbsp;Published</i>  : <i className="fas fa-circle text-red-500 mr-2">&nbsp;Archived</i>}
                </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

RecentListBlog.defaultProps = {
  color: "light",
};

RecentListBlog.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
