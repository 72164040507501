
import React from "react";
import { useHistory, Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import axios from "axios";
import Swal from 'sweetalert2';

const BlogDropdown = (props) => {

  const navigate = useHistory();
  let timerInterval;
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const viewBlog = (blog) => {
    closeDropdownPopover();
    console.log(blog);
    navigate.push('/admin/blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: blog.status
      }
    })
  }

  const editBlog = (blog) => {
    closeDropdownPopover();
    console.log(blog);
    navigate.push('/admin/blogs/update', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: "John Doe",
        status: blog.status
      }
    })
  }

  function archiveBlog(blog){
    closeDropdownPopover();
    let headers= { 
        'Accept':'*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    const formData = new FormData();
    formData.append('id', blog.id);
    formData.append('title', blog.title);
    formData.append('body', blog.body);
    formData.append('image', blog.image);
    formData.append('dateupdated', blog.dateadded);
    formData.append('updatedby', blog.addedby);
    formData.append('status', 2);

    axios.put('https://paytack.com/paytackapi/blogs/', formData, {headers}).then(function(response){
        console.log(response.data);
        if(response.data['status'] == '1'){
              Swal.fire({
                icon:  "success",
                title: "Success!",
                html: "Blog archived. Redirecting to blogs.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                  navigate.push('/admin/blogs/', {
                  });

                  props.refreshBlogs();
                }
              });
            }
    });
  
  }

  function publishBlog(blog){
    closeDropdownPopover();
    let headers= { 
        'Accept':'*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    const formData = new FormData();
    formData.append('id', blog.id);
    formData.append('title', blog.title);
    formData.append('body', blog.body);
    formData.append('image', blog.image);
    formData.append('dateupdated', blog.dateadded);
    formData.append('updatedby', blog.addedby);
    formData.append('status', 1);

    axios.put('https://paytack.com/paytackapi/blogs/', formData, {headers}).then(function(response){
        console.log(response.data);
        if(response.data['status'] === 1){
              Swal.fire({
                icon:  "success",
                title: "Success!",
                html: "Blog published. It is visible on the home and insights pages. Redirecting to blogs.",
                timer: 5000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                  navigate.push('/admin/blogs/', {
                  });

                  props.refreshBlogs();
                }
              });
            }
    });
  
  }

  return (
    <>
      <a
        className="text-blueGray-500 py-1 px-3"
        
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <ul>
        <li
          
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={() => {
            publishBlog(props.blog)
          }}
        >
            <i style={{color: "green"}} className={"fas fa-check mr-2 text-sm "}></i>
            Publish
        </li>
        <li
          
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={() => {
            viewBlog(props.blog)
          }}
        >
            <i style={{color: "teal"}} className={"fas fa-eye mr-2 text-sm "}></i>
            View
        </li>
        <li
          
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={() => {
            editBlog(props.blog)
          }}
        >
            <i style={{color: "orange  "}} className={"fas fa-edit mr-2 text-sm "}></i>
            Edit                                                    
        </li>
        <li
          
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={() => {
            archiveBlog(props.blog)
          }}
        >
            <i style={{color: "red"}} className={"fas fa-archive mr-2 text-sm "}></i>
            Archive
        </li>
        </ul>
      </div>
    </>
  );
};

export default BlogDropdown;
